<template>
<div class="row row-custom" v-if="SubCategory">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل  التصنيف   
                 </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr><td>رقم التصنيف </td><td>:</td><td>{{SubCategory.offline_id}}</td></tr>
                  <tr><td>الاسم بالعربي </td><td>:</td><td>{{SubCategory.name.ar}}</td></tr>
                 <tr><td>الاسم بالانجليزي</td><td>:</td><td>{{SubCategory.name.en}}</td></tr>

                 <tr>
                     <td>صورة التصنيف</td><td>:</td><td>
                     <template v-if="SubCategory.img">
                     <img width="100" height="100" :src="'https://pode.ly'+SubCategory.img"/>
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                     </td>
                </tr>
             </table>
            </div>                      
        </div>

    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
export default {
      computed:{
    ...mapState({
        SubCategory:state=>state.admin.subcategories.SubCategory.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const category_uuid = to.params.category_uuid;
        const sub_category_uuid = to.params.sub_category_uuid;
 
        store.dispatch('admin/fetchSubCategory', {sub_category_uuid,category_uuid}).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>